import React from 'react'
import siteLogo from "../assets/site-logo-white.svg"
import { Link } from 'react-scroll'

function MaintenanceMode() {
  return (
    <div className='maintenance_root'>
      <div className='container'>
      <h1><span className='span-coloe'>Maintenance in Progress: </span> <br /> Flight Delay Notification</h1>
      <div className='content'>
        <p>Attention flight crew,</p>
        <p>We apologize for the inconvenience, but our website is currently undergoing scheduled maintenance — think of it as a routine checkup to ensure a smooth flight. During this time, our services are temporarily grounded.</p>
        <p>Safe travels and see you soon!</p>
        <p>Crewmates Ground Crew</p>
      </div>
      <div className='site-logo'>
        <img loading="lazy" src={siteLogo} alt="site-image" />
        <a href='https://www.facebook.com/groups/crewmatescrashpads/' className='facbook-btn bg-primary-blue hover:bg-primary-blue-dark cursor-pointer transition-colors'>
        Join Our Facebook Group
        </a>
      </div>
      </div>
    </div>
  )
}

export default MaintenanceMode