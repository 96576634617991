import React from 'react'

const FullPageLoader = () => {
    const loaderContainerStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
        fontFamily: 'Poppins, sans-serif',
    }

    const loaderStyle = {
        border: '8px solid #f3f3f3', // Light grey
        borderTop: '8px solid #23426e', // Blue
        borderRadius: '50%',
        width: '60px',
        height: '60px',
        marginBottom: '20px',
        animation: 'spin 1s linear infinite',
    }

    const textStyle = {
        fontSize: '1.2rem',
        color: '#23426e',
        textAlign: 'center',
    }

    const keyframes = `
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
    `

    return (
        <div style={loaderContainerStyle}>
            <style>{keyframes}</style>
            <div style={loaderStyle}></div>
            <div style={textStyle}>
                Loading... <br />
                Please wait a moment.
            </div>
        </div>
    )
}

export default FullPageLoader
