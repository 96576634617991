import { constant } from '../_config/constant'

export const getAuthHeader = () => {
    const token = localStorage.getItem(constant.TOKEN_KEY)
    if (token) {
        return {
            headers: {
                Authorization: 'Bearer ' + token,
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': '*',
            },
        }
    } else {
        return { headers: {} }
    }
}

export const getBasicAuthHeader = () => {
    return {
        headers: {
            Authorization: 'Basic ' + btoa(`${constant.BASIC_AUTH.USERNAME}:${constant.BASIC_AUTH.PASSWORD}`),
        },
    }
}

export const getRestAuthHeader = () => {
    const token = localStorage.getItem(constant.RESET_TOKEN_KEY)
    if (token) {
        return { headers: { Authorization: 'Bearer ' + token } }
    } else return { headers: {} }
}

export const setToken = (token) => {
    localStorage.setItem(constant.TOKEN_KEY, token)
}

export const getToken = () => {
    return localStorage.getItem(constant.TOKEN_KEY)
}

export const removeToken = () => {
    return localStorage.removeItem(constant.TOKEN_KEY)
}

export const setData = (data) => {
    localStorage.setItem(constant.DATA_KEY, JSON.stringify(data))
}

export const getData = () => {
    return JSON.parse(localStorage.getItem(constant.DATA_KEY))
}

export const setResetToken = (token) => {
    localStorage.setItem(constant.RESET_TOKEN_KEY, token)
}

export const getResetToken = () => {
    localStorage.getItem(constant.RESET_TOKEN_KEY)
}

export function capitalizeEachWord(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase())
}
