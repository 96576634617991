import axios from 'axios'
import { constant } from '../_config/constant'
import { getAuthHeader, getBasicAuthHeader, getRestAuthHeader } from '../_helper/header'
import { handleResponse, handleErrorResponse } from '../_helper/utils'

const api = axios.create({ baseURL: constant.apiUrl })

export const login = async (data) => {
    const response = await api.post('/user/login', data, getBasicAuthHeader())
    return handleResponse(response)
}

export const checkIsRegistered = async (data) => {
    const response = await api.post('user/checkIsRegistered', data, getBasicAuthHeader())
    return handleResponse(response)
}

export const register = async (data) => {
    const response = await api.post('/user/register', data, getBasicAuthHeader())
    return handleResponse(response)
}

export const switchUserType = async (data) => {
    const response = await api.post('/user/becomeBoth', data, getBasicAuthHeader())
    return handleResponse(response)
}

export const socialRegister = async (data) => {
    const response = await api.post('/user/register', data, getBasicAuthHeader())
    return response
}

export const forgetPassword = async (data) => {
    const response = await api.post('/user/forgotPassword', data, getBasicAuthHeader())
    return handleResponse(response)
}

export const changePassword = async (data) => {
    try {
        const response = await api.post('/user/changePassword', data, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const updateProfile = async (data) => {
    try {
        let fd = new FormData()
        fd.append('name', data.name)
        fd.append('personalEmail', data.personalEmail)
        fd.append('phone', data.phone)
        fd.append('countryCode', data.countryCode)
        fd.append('airlineEmail', data.airlineEmail)
        fd.append('dob', data.dob)
        fd.append('crewType', data.crewType)
        fd.append('file', data.fileData)

        const response = await api.post('/user/profile', fd, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const verifyEmail = async (data) => {
    const response = await api.post('/user/verifyEmail', data, getRestAuthHeader())
    return handleResponse(response)
}

export const verifyPersonalEmailOTP = async (data) => {
    const response = await api.post('/user/verifyPersonalEmailOTP', data, getAuthHeader())
    return handleResponse(response)
}

export const verifyPhone = async (data) => {
    const response = await api.post('/user/verifyPhone', data, getRestAuthHeader())
    return handleResponse(response)
}

export const sendAirlineEmailVerificationLink = async (data) => {
    try {
        const response = await api.post('/user/sendMailVerificationLink', data, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const sendAirlineEmailVerificationLinkOTP = async (data) => {
    try {
        const response = await api.post('/user/send-airline-email-verification-link', data, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export async function sendVerifyPhoneOtp(data) {
    try {
        const response = await api.post('/user/sendVerifyPhoneNumber', data, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const resetPassword = async (data) => {
    const response = await api.post('/user/resetPassword', data, getRestAuthHeader())
    return handleResponse(response)
}

export async function updatePersonalEmailAndOtp(data) {
    const response = await api.post('/user/update-personal-email', data, getAuthHeader())
    return handleResponse(response)
}

export const updateIdVerificationStatus = async () => {
    try {
        const response = await api.get('/user/updateIdVerificationStatus', getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const updateSocialAuthenticationStatus = async (data) => {
    try {
        const response = await api.post('/user/updateSocialAuthentication', data, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const getProfile = async () => {
    try {
        const response = await api.get('/user/profile', getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const getAddressList = async () => {
    try {
        const response = await api.get('/user/address', getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const addNewAddress = async (data) => {
    try {
        const response = await api.post('/user/address', data, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const deleteAddress = async (id) => {
    try {
        const response = await api.delete(`/user/address/${id}`, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const updateAddress = async (id, data) => {
    try {
        const response = await api.put(`/user/address/${id}`, data, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export async function verifyPhoneOTP(data) {
    try {
        const response = await api.post('/user/verifyPhoneNumber', data, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export const getNotificationStatus = async () => {
    try {
        const response = await api.get(`/user/notification`, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}
export const setNotificationStatus = async (data) => {
    try {
        const response = await api.post(`/user/notification/status`, data, getAuthHeader())
        return handleResponse(response)
    } catch (error) {
        handleErrorResponse(error)
    }
}

export function logout() {
    localStorage.removeItem(constant.TOKEN_KEY)
    localStorage.removeItem(constant.DATA_KEY)
    localStorage.removeItem(constant.TEMP_REF)
    window.location.href = '/'
}
